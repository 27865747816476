<template>
  <v-app id="app">
    <div>
      <v-main>
        <v-container v-if="notices.length > 0 && isTwoWheelerRoute">
          <div
            class="d-flex my-2"
            v-for="(data, index) in notices"
            :key="index"
          >
            <v-icon color="error">mdi-bullhorn-outline</v-icon>
            <div class="ml-2 error--text">
              <div>
                <strong>{{ data.heading }}</strong>
              </div>
              <div>{{ data.text }}</div>
            </div>
          </div>
        </v-container>
        <notifications
          group="auth"
          position="bottom center"
          class="mt-2 text-center"
        />
        <v-container class="text-right hidden-md-and-down">
          <div class="d-flex justify-end px-5">
            <a
              href="/two-wheeler-services/blogs"
              class="black--text text-decoration-none text-caption link my-auto"
            >
              {{ $t("Blogs") }}
            </a>
            <div class="mx-3 text--disabled my-auto">|</div>
            <div
              class="text-caption link my-auto"
              @click="
                $router.push(
                  '/help-and-support/frequently-asked-questions/rentals'
                )
              "
            >
              {{ $t("FAQ?") }}
            </div>
            <div class="mx-3 text--disabled my-auto">|</div>
            <div class="text-caption link my-auto">
              <v-menu offset-y bottom left open-on-hover>
                <template v-slot:activator="{ on }">
                  <div v-on="on">
                    {{ $t("Support") }}
                  </div>
                </template>
                <v-card outlined elevation="0">
                  <v-list>
                    <v-list-item
                      v-for="(data, index) in supportItems"
                      :key="index + 'n'"
                      @click="$router.push(data.routes)"
                    >
                      <v-list-item-icons>
                        <v-icon size="20" color="">{{ data.leftIcons }}</v-icon>
                      </v-list-item-icons>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle-2 ml-3">{{
                          $t(data.title)
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icons>
                        <v-icon size="20">{{ data.rightIcons }}</v-icon>
                      </v-list-item-icons>
                    </v-list-item>
                    <v-list-item>
                      <div
                        class="d-flex justify-space-between"
                        style="width: 100%"
                      >
                        <v-btn
                          icon
                          @click="
                            redirect(
                              'https://api.whatsapp.com/send/?phone=919148518401&amp;text=Hi!'
                            )
                          "
                        >
                          <v-icon size="22" color="success"
                            >mdi-whatsapp</v-icon
                          >
                        </v-btn>
                        <v-btn icon @click="redirect('tel:+919148518401')">
                          <v-icon size="22" color="primary">mdi-phone</v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          @click="redirect('mailto:info@on-track.in')"
                        >
                          <v-icon size="22" color="red darken-1"
                            >mdi-email</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title class="text-caption">
                        {{ $t("Our whatsapp number cannot be used") }}<br />
                        {{ $t("for calls.This is a chat only number.") }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card>
              </v-menu>
            </div>
            <div class="mx-3 text--disabled my-auto">|</div>
            <div class="text-caption my-auto">
              <v-chip small @click="redirect('tel:+919148518401')">
                <v-icon size="15" color="black">mdi-phone</v-icon>
                +91 9148518401
              </v-chip>
            </div>
            <div class="mx-3 text--disabled my-auto">|</div>
            <v-badge
              bordered
              color="error accent-4"
              dot
              offset-x="0"
              offset-y="10"
              class="my-auto"
            >
              <v-menu offset-y bottom left open-on-hover>
                <template v-slot:activator="{ on }">
                  <div class="text-caption text-uppercase link" v-on="on">
                    <v-icon size="15" color="black">mdi-translate</v-icon>
                    {{ $t(locale) }}
                  </div>
                </template>
                <v-card>
                  <v-container>
                    <v-radio-group v-model="locale">
                      <v-radio
                        v-for="n in locales"
                        :key="n + '-lang'"
                        :label="`${n.text}`"
                        :value="n.value"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-card>
              </v-menu>
            </v-badge>
          </div>
        </v-container>
        <div
          class="sticky-0 white"
          style="box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.07)"
        >
          <v-container class="py-md-0 my-md-0">
            <div class="d-flex justify-space-between">
              <div class="my-auto">
                <div class="my-auto hidden-md-and-up">
                  <v-icon size="30" class="" @click.stop="drawer = !drawer"
                    >mdi-menu</v-icon
                  >
                </div>
                <v-img
                  @click="$router.push('/two-wheeler-rental/models')"
                  class="hidden-md-and-down"
                  src="@/assets/logos/ontrack-logo.png"
                  height="auto"
                  width="100px"
                  contain
                >
                </v-img>
              </div>
              <div class="text-right d-flex">
                <v-menu open-on-hover :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      @click="$router.push('/two-wheeler-rental/models')"
                      class="my-auto hidden-md-and-down menuItem"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("Rentals") }}
                    </div>
                  </template>
                  <v-list>
                    <v-list-item to="/two-wheeler-rental/models">
                      <v-list-item-title>Fleet</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/two-wheeler-rental/how-it-works">
                      <v-list-item-title>{{
                        $t("How it works")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div
                  @click="$router.push('/two-wheeler-loan/select-make')"
                  class="my-auto hidden-md-and-down menuItem"
                  text
                >
                  {{ $t("Bike Loan") }}
                </div>
                <div
                  @click="$router.push('/two-wheeler-loan-against-vehicle')"
                  class="my-auto hidden-md-and-down menuItem"
                  text
                >
                  {{ $t("Loan Against Vehicle") }}
                </div>
                <!-- <div
                  @click="$router.push('/two-wheeler-used-bike/bikes')"
                  class="my-auto hidden-md-and-down menuItem"
                  text
                >
                  {{ $t("Used Bikes") }}
                </div> -->

                <v-menu offset-y open-on-hover bottom left v-if="userData">
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="my-auto hidden-md-and-down menuItem"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t("HI") }}, {{ userData.fName }}
                    </div>
                  </template>
                  <v-list>
                    <v-list-item to="/account">
                      <v-list-item-title>{{ $t("Profile") }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/account/bookings">
                      <v-list-item-title>{{
                        $t("My Bookings")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/account/notification">
                      <v-list-item-title>{{
                        $t("Account Notification")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item to="/account/help-and-support">
                      <v-list-item-title>{{
                        $t("Help and Support")
                      }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout">
                      <v-list-item-title>{{ $t("Logout") }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <div
                  v-else
                  @click="$router.push('/login')"
                  class="my-auto hidden-md-and-down menuItem"
                  text
                >
                  {{ $t("Login") }}
                </div>

                <div class="hidden-md-and-up my-auto">
                  <v-badge
                    bordered
                    color="error accent-4"
                    dot
                    offset-x="0"
                    offset-y="10"
                    class="my-auto"
                  >
                    <div
                      class="text-caption text-uppercase link"
                      @click="sheet = !sheet"
                    >
                      <v-icon size="15" color="black">mdi-translate</v-icon>
                      {{ $t(locale) }}
                    </div>
                    <v-bottom-sheet v-model="sheet">
                      <v-sheet class="" height="100%">
                        <v-card>
                          <v-container>
                            <div class="d-flex justify-space-between">
                              <div
                                class="text-subtitle font-weight-bold my-auto"
                              >
                                Select a language
                              </div>
                              <v-btn icon @click="sheet = !sheet">
                                <v-icon size="25">mdi-close</v-icon>
                              </v-btn>
                            </div>

                            <v-radio-group v-model="locale">
                              <v-radio
                                @click="sheet = !sheet"
                                v-for="n in locales"
                                :key="n + 'loc'"
                                :label="`${n.text}`"
                                :value="n.value"
                              ></v-radio>
                            </v-radio-group>
                          </v-container>
                        </v-card>
                      </v-sheet>
                    </v-bottom-sheet>
                  </v-badge>
                </div>

                <v-btn
                  class="my-auto ml-3 hidden-md-and-up"
                  @click.stop="openBottomSheet"
                  icon
                  large
                  depressed
                >
                  <v-icon color="black">mdi-help-circle</v-icon>
                </v-btn>
              </div>
            </div>
          </v-container>
        </div>
        <router-view></router-view>
      </v-main>
    </div>
    <v-bottom-sheet v-model="bottomSheet">
      <v-sheet height="100%" class="">
        <v-card-title class="d-flex justify-space-between">
          <div class="text-subtitle font-weight-bold">Support</div>
          <v-btn icon @click="bottomSheet = !bottomSheet">
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-list>
          <v-list-item
            class="mb-2"
            v-for="(data, index) in supportItems"
            :key="index + 'support'"
            @click="$router.push(data.routes)"
          >
            <v-list-item-icons>
              <v-icon size="25">{{ data.leftIcons }}</v-icon>
            </v-list-item-icons>
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 ml-3">{{
                $t(data.title)
              }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-icons>
              <v-icon size="25">{{ data.rightIcons }}</v-icon>
            </v-list-item-icons>
          </v-list-item>
          <v-list-item>
            <div class="d-flex justify-space-between py-4" style="width: 100%">
              <v-btn
                icon
                @click="
                  redirect(
                    'https://api.whatsapp.com/send/?phone=919148518401&amp;text=Hi!'
                  )
                "
              >
                <v-icon size="28" color="success">mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn icon @click="redirect('tel:+919148518401')">
                <v-icon size="28" color="primary">mdi-phone</v-icon>
              </v-btn>
              <v-btn icon @click="redirect('mailto:info@on-track.in')">
                <v-icon size="28" color="red darken-1">mdi-email</v-icon>
              </v-btn>
            </div>
          </v-list-item>
          <v-list-item>
            <v-list-item-title class="text-subtitle text-center pt-2">
              {{ $t("Our whatsapp number cannot be used") }}<br />
              {{ $t("for calls.This is a chat only number.") }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-bottom-sheet>
    <v-footer dark class="hidden-md-and-down">
      <v-container class="py-15">
        <v-row class="py-15 text-subtitle-1">
          <v-col class="footer-text" cols="6" md="3">
            <a href="/model-display/3/rent-honda-dio-bangalore"
              >{{ $t("Rent Honda Dio In Bangalore") }}
            </a>
            <a href="/model-display/14/rent-bajaj-avenger-150-street-bangalore">
              {{ $t("Rent Bajaj Avenger 150 street In Bangalore") }}
            </a>
            <a href="/model-display/16/rent-bajaj-avenger-220-cruise-bangalore">
              {{ $t("Rent Bajaj Avenger 220 Cruise In Bangalore") }}
            </a>
            <a href="/model-display/2/rent-honda-navi-bangalore">
              {{ $t("Rent Honda Navi In Bangalore") }}
            </a>
            <a href="/model-display/1/rent-bajaj-ct-100-bangalore">
              {{ $t("Rent Bajaj CT100 In Bangalore") }}
            </a>
            <a href="/model-display/3/rent-honda-dio-bangalore">
              {{ $t("Rent Honda Dio In Bangalore") }}
            </a>
            <a href="/model-display/19/rent-TVS-sport-bangalore">
              {{ $t("Rent TVS Sport 150cc In Bangalore") }}
            </a>
            <a href="/model-display/20/rent-TVS-Jupiter-bangalore">
              {{ $t("Rent TVS Jupiter In Bangalore") }}
            </a>
            <a href="/model-display/22/rent-Hero-Maestro-bangalore">
              {{ $t("Rent Hero Maestro In Bangalore") }}
            </a>
            <a href="/model-display/28/rent-hero-destini-bangalore">
              {{ $t("Rent Hero Destini In Bangalore") }}
            </a>
          </v-col>
          <v-col class="footer-text" cols="6" md="3">
            <a href="/model-display/29/rent-hero-pleasure-bangalore">
              {{ $t("Rent Hero Pleasure In Bangalore") }}
            </a>
            <a href="/model-display/4/rent-suzuki-access-125-bangalore">
              {{ $t("Rent Suzuki Access 125cc In Bangalore") }}
            </a>
            <a href="/model-display/9/rent-honda-activa-bangalore">
              {{ $t("Rent Honda Activa In Bangalore") }}
            </a>
            <a href="/model-display/18/rent-honda-aviator-bangalore">
              {{ $t("Rent Honda Aviator In Bangalore") }}
            </a>
            <a href="/model-display/31/rent-bajaj-CT110-bangalore">
              {{ $t("Rent Bajaj CT110 In Bangalore") }}
            </a>
            <a href="/model-display/32/rent-bajaj-CT100VI-bangalore">
              {{ $t("Rent Bajaj CT100VI In Bangalore") }}
            </a>
            <a href="/model-display/13/rent-bajaj-pulsar-150-bangalore">
              {{ $t("Rent Bajaj Pulsar In Bangalore") }}
            </a>
            <a href="/model-display/10/rent-aprilia-sr-150-bangalore">
              {{ $t("Rent Aprilia SR150 In Bangalore") }}
            </a>
            <a href="/model-display/17/rent-honda-cb-hornet-bangalore">
              {{ $t("Rent Honda CB Hornet In Bangalore") }}
            </a>
          </v-col>
          <v-col class="footer-text" cols="6" md="3">
            <a href="/account/help-and-support">
              {{ $t("Help and Support") }}
            </a>
            <a href="/help-and-support/frequently-asked-questions/rentals">
              {{ $t("Frequently asked questions") }}
            </a>
            <a href="https://on-track.in/category/uncategorized/">
              {{ $t("Blogs") }}
            </a>
            <a href="https://shop.on-track.in/"> {{ $t("Finance") }} </a>
          </v-col>
          <v-col class="text-right">
            <v-img
              width="100"
              class="mx-auto"
              src="https://book.on-track.in/img/ontrack-logo.f3184495.png"
            ></v-img>
            <div class="mt-4 text-left">
              {{ $t("ontrack-des") }}
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-navigation-drawer v-model="drawer" absolute temporary>
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="">
          <v-list-item class="px-2" v-if="userData" to="/account">
            <v-list-item-avatar>
              <v-img
                :src="
                  userData.profilePicture ||
                  'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTY2ZZCuLEVcjMXvWketGb-83uxH1jcGhuK_1yteLMNrw&usqp=CAU&ec=48600112'
                "
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-title class="text-subtitle-1">{{
              userData.fName
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item two-line v-else to="/login">
            <v-list-item-content>
              <v-list-item-title>{{ $t("Login") }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ $t("Login to see booking, profile etc") }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mb-4"></v-divider>
          <!-- <div class="d-flex justify-space-between mb-4">
            <div class="my-auto font-weight-bold">{{ $t("Language") }}</div>
            <div style="width: 90px">
              <v-select
                hide-details
                dense
                outlined
                @change="changeLocale"
                v-model="locale"
                :items="locales"
              >
              </v-select>
            </div>
          </div> -->

          <!-- <v-list-item class="" to="/two-wheeler-rental/models" text>{{
            $t("Home")
          }}</v-list-item> -->
          <v-list-item class="" to="/two-wheeler-rental/models" text>{{
            $t("Rentals")
          }}</v-list-item>
          <!-- <v-list-item class="" to="/two-wheeler-used-bike/bikes" text>{{
            $t("Used Bike")
          }}</v-list-item> -->
          <v-list-item class="" to="/two-wheeler-loan/select-make" text>{{
            $t("Bike Loan")
          }}</v-list-item>
          <v-list-item class="" to="/two-wheeler-loan-against-vehicle" text>{{
            $t("Loan Against Vehicle")
          }}</v-list-item>
          <v-list-item class="" to="/two-wheeler-services/blogs" text>{{
            $t("Blogs")
          }}</v-list-item>
          <v-list-item class="" to="/two-wheeler-rental/how-it-works" text>{{
            $t("How it works")
          }}</v-list-item>
          <v-list-item v-if="userData" class="" to="/account">
            {{ $t("Profile") }}
          </v-list-item>
          <v-list-item v-if="userData" class="" to="/account/bookings">
            {{ $t("My Bookings") }}
          </v-list-item>
          <v-list-item v-if="userData" class="" to="/account/help-and-support">
            {{ $t("Help and Support") }}
          </v-list-item>
          <v-list-item v-if="userData" class="" @click="logout">
            {{ $t("Logout") }}
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-dialog persistent max-width="300" v-model="languageDialog">
      <v-card>
        <v-container>
          <h3>We are local now!</h3>
          <div>please select your preferred language</div>
          <v-radio-group v-model="locale">
            <v-radio
              v-for="n in locales"
              :key="n + '-lang'"
              :label="`${n.text}`"
              :value="n.value"
            ></v-radio>
          </v-radio-group>
        </v-container>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { HTTP } from "./services/axios";
import { logFirebaseEvent } from "@/services/firebase.js";
export default {
  name: "App",

  components: {},

  data: () => ({
    notices: [],
    fab: false,
    drawer: false,
    bottomSheet: false,
    group: "",
    sheet: false,
    languageDialog: false,

    locale: "",
    locales: [
      { text: "English", value: "en" },
      { text: "हिन्दी", value: "hi" },
      { text: "Kannada", value: "ka" },
    ],

    supportItems: [
      {
        leftIcons: "mdi-comment-question",
        title: "Complains",
        rightIcons: "mdi-chevron-right",
        routes: "/complaint-form",
      },
      {
        leftIcons: "mdi-frequently-asked-questions",
        title: "FAQ?",
        rightIcons: "mdi-chevron-right",
        routes: "/help-and-support/frequently-asked-questions",
      },
      {
        leftIcons: "mdi-clipboard-text",
        title: "Terms and Conditions",
        rightIcons: "mdi-chevron-right",
        routes: "/help-and-support/terms-and-conditions",
      },
    ],

    //
  }),
  updated() {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  },
  async mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    const source =
      urlParams.get("utm_source") || urlParams.get("source") || "direct";
    const medium =
      urlParams.get("utm_medium") || urlParams.get("medium") || "website";
    const campaign =
      urlParams.get("utm_campaign") ||
      urlParams.get("campaign") ||
      urlParams.get("campaignid") ||
      "direct";

    localStorage.setItem("trafficSource", source);
    localStorage.setItem("trafficSourceCampaign", campaign);
    localStorage.setItem("trafficSourceMedium", medium);

    logFirebaseEvent("website-first-visit", {
      event_category: "view-event",
      event_label: "Website Main Visit",
      value: 1,
    });

    await this.$recaptchaLoaded();
    setTimeout(() => {
      let language = localStorage.getItem("language");
      const currentPath = this.$route.fullPath;

      if (!language && !currentPath.includes("two-wheeler-loan")) {
        this.languageDialog = true;
      } else {
        this.locale = language ? language : "en";
        this.$i18n.locale = language ? language : "en";
      }
    }, 1200);

    try {
      const notice = await HTTP.get("/v1/app/notices");
      this.notices = notice.data;
    } catch {
      console.log("err");
    }
  },
  watch: {
    locale() {
      this.changeLocale();
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("AUTH_LOGOUT").then(() => {
        this.$router.push("/");
      });
    },
    redirect(url) {
      window.location.href = url;
    },
    openBottomSheet() {
      this.bottomSheet = true;
    },

    changeLocale() {
      localStorage.setItem("language", this.locale);
      this.$i18n.locale = this.locale;
      this.languageDialog = false;
      logFirebaseEvent("language-" + this.locale, {
        event_category: "click-event",
        event_label: "Language Toggle",
        value: 1,
      });
      // window.location.reload();
    },
  },
  computed: {
    isTwoWheelerRoute() {
      return this.$route.path.includes("two-wheeler-rental");
    },
    userData() {
      return this.$store.getters.getLoggedInUser;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --default-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif;
}
* {
  font-family: var(--default-font);
  /* font-family: "Montserrat", sans-serif; */
  font-family: "Verdana", sans-serif;
  /* font-family: "Poppins", sans-serif; */
  font-optical-sizing: auto;
}

.footer-text a {
  text-decoration: none;
  color: white !important;
  display: block;
  padding-bottom: 10px;
}
.footer-text a:hover {
  text-decoration: underline;
}
.safe-area {
  padding-top: env(safe-area-inset-top) !important;
  height: auto !important;
}
.safe-area-bottom {
  padding-bottom: env(safe-area-inset-bottom) !important;
  height: calc(env(safe-area-inset-bottom) + 50px) !important;
}
.safe-area-topbar {
  padding-top: calc(env(safe-area-inset-top)) !important;
}

.md-max-500 {
  max-width: 500px;
}
@media only screen and (max-width: 600px) {
  .scroll-container {
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap !important;
    overflow: scroll !important;
  }
  .md-max-500 {
    max-width: 100% !important;
  }
}

.v-btn,
.v-tab {
  text-decoration: none !important;
  text-transform: capitalize !important;
  letter-spacing: normal !important;
}

@media (max-width: 1263px) {
  .toggebleBtn {
    display: block;
  }
}
@media (min-width: 1264px) {
  .toggebleBtn {
    display: none;
  }
}

.scroll-container {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.vue-notification {
  text-align: center;
}
.content .v-toolbar__content,
.v-toolbar__extension {
  padding: 0px !important;
}
.sticky-0 {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  font-size: 20px;
  z-index: 3;
}
.link:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
.menuItem {
  font-size: 15px;
  padding: 16px !important;
  text-transform: none;
}
.menuItem:hover {
  background: #f5f5f5;
  cursor: pointer;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
